export const faqQuestions = [
  "What is Perped?",
  "What is an on-chain perpetual?",
  "What is futures trading?",
  "What are real world assets?",
  "How is Perped unique?",
  "How do I begin trading on Perped?",
  "What chains does Perped run on?",
  "How does Perped get price data for trading?",
  "What is RRP?",
  "What is an Airsigner?",
  "What is OEV (Oracle Extractable Value)?",
  "What is MEV (Maximum Extractable Value)?",
  "Is Perped secure to use?",
  "Does Perped have a token? what is it used for?",
  "What is a DAO?",
  "What is the total token supply?",
  "How can I contact the Perped team?",
  "How can I join the Perped team?",
];

export const faqAnswers = [
  "Perped is the first fully featured perpetuals platform for real-world assets.",
  "A perpetual is a special type of futures contract, but unlike the traditional form of futures, it doesn’t have an expiry date. Perped brings all of this on-chain powered by smart contracts.",
  "Futures trading is an agreement to buy or sell a commodity, currency, or another instrument at a predetermined price without any expiry date.",
  "Real World Assets (RWAs) is the term used for tangible assets, such as real estate properties, artwork, and commodities, in the context of blockchain networks.",
  "Perped is powered by a unique oracle model that helps bring any real world asset on-chain without much turbulence.",
  "Go to the Home page -> Click on 'Trade Now'/'Enter Now', this will take you to the main application -> Connect your wallet -> Select the stock you want to trade -> Enter the amount of stocks you would like to buy -> Enter the amount of leverage you want on your trade -> Select your position type(long/short) -> Click buy. After this you will be asked to approve the required amount of USDC for the trade to happen. As soon as the transaction is confirmed another transaction will be initiated that on confirmation finally assigns the position to the user. All of this can be viewed under the 'Open Positions' section.",
  "Perped is going to be launched on Polygon zkEVM and more L2s in the coming future.",
  "Perped uses the RRP (request-response protocol) of the open-source Airnode software, which was developed by API3. An Airnode can be directly run by an API provider, which allows a smart contract to get data directly from the provider with no dependence on middlemen.",
  "The request-response protocol (RRP) is an oracle mechanism by which an API is called by an oracle contract, which then fulfills the request by making a transaction to submit the requested data onto the blockchain to trigger a smart contract.",
  "Perped is the first project to introduce an extension of the Airnode known as the Airsigner. The Airsigner allows the data providers and dApps to monetize OEV (oracle extractable value) that is taking place within their domains, rather than the capital being redirected to third parties like keepers.",
  "OEV refers to oracles making use of their position to capture MEV that would otherwise have gone to third parties - this includes liquidation events and arbitrage.",
  "MEV refers to the maximum value that can be extracted from block production in excess of the standard block reward and gas fees by including, excluding, and changing the order of transactions in a block.",
  "Perped's smart contracts were audited successfully by SlowMist",
  "Perped will have a governance token that enables voting participation in its future DAO, with the additional benefit of gaining real yield from fees and value returned to token holders from liquidation in the protocol.",
  "A decentralized autonomous organization (DAO) is an emerging form of organizational structure that is programmatically defined using smart contracts and has no central governing body. DAOs are used to make decisions in a bottom-up management approach.",
  "Only 100 million tokens will ever be minted. The details of the tokenomics can be viewed on the documentation.",
  "There are multiple social media links available at the website's footer and on the navigation menu under the 'Contact' section. Feel free to message us via those media.",
  "We don't have any open positions at the moment, but feel free to ask us on our social channels in the off chance that there is such an opening.",
];
